<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editUser')  + ' (' + this.payload.id + ')' : $t('common.form.title.createUser')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.email"
                                        name="email"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-select-group v-model="form.role"
                                         name="role"
                                         :options="getRolesList()"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                                         text-field="role"
                                         value-field="role"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.firstname"
                                        name="firstname"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.lastname"
                                        name="lastname"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.position"
                                        name="position"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.phone"
                                        name="phone"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.mobile"
                                        name="mobile"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-select-group v-model="form.copy_user_id"
                                         :options="getUsersList()"
                                         name="copy_user_id"
                                         :label-prefix="labelPrefix"
                                         text-field="fullname"
                                         value-field="id"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="" v-if="reset">
                        <label class="p-2">{{ $t('login.label.password') }}</label>
                        <b-button
                            size="lg"
                            @click="$root.$children[0].openModal('password-modal', {id:form.id}, refreshTable, {width: '800px'})">
                            <font-awesome-icon icon="key"/>
                        </b-button>
                    </b-col>
                </b-row>
                <b-card class="mt-3" :header="$t('users.label.smtp.smtp')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="6">
                                <zw-input-group v-model="form.smtp_host"
                                                name="smtp_host"
                                                :label-prefix="labelPrefix+'smtp.'"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-input-group v-model="form.smtp_port"
                                                name="smtp_port"
                                                :label-prefix="labelPrefix+'smtp.'"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-input-group v-model="form.smtp_username"
                                                name="smtp_username"
                                                :label-prefix="labelPrefix+'smtp.'"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-input-group v-model="form.smtp_password"
                                                name="smtp_password"
                                                :label-prefix="labelPrefix+'smtp.'"
                                                type="password"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-select-group v-model="form.smtp_secure_connection"
                                                 :options="['ssl','tls']"
                                                 empty
                                                 :empty-disabled="false"
                                                 name="smtp_secure_connection"
                                                 :label-prefix="labelPrefix+'smtp.'"
                                ></zw-select-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-input-group v-model="form.smtp_sender_name"
                                                name="smtp_sender_name"
                                                :label-prefix="labelPrefix+'smtp.'"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="12">
                                <zw-switch-group v-model="form.incoming_emails"
                                                 name="incoming_emails"
                                                 :label-prefix="labelPrefix+'smtp.'"
                                />
                            </b-col>
                            <b-col cols="6">
                                <b-form-group :label="$t('users.label.test_smtp')">
                                    <b-button @click="checkSmtp()" variant="danger">
                                        {{ $t('common.button.test') }}
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <div class="form-actions">
                    <b-row>
                        <b-col sm="12" class="text-sm-right">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'UserModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            reset: false,
            callback: null,
            defaultForm: {
                firstname: '',
                copy_user_id: 0,
            },
            form: {},
            labelPrefix: 'users.label.',
        }
    },
    methods: {
        ...mapGetters('Users', ['getUser', 'getUsersList']),
        ...mapGetters('Roles', ['getRolesList']),
        shown() {
            this.reset = this.payload.reset
            const user = this.$store.dispatch('Users/fetchUser', this.payload.id)
            const usersList = this.$store.dispatch('Users/fetchUsersList')
            const roles = this.$store.dispatch('Roles/fetchRolesList')

            Promise.all([user, roles, usersList])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getUser()))
                    }

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Users/saveUser', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                            this.$root.afterSettingsUpdate()
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        checkSmtp() {
            this.loading = true
            this.$store.dispatch('Users/checkSmtp', this.form)
                .then((response) => {
                    if (response.data.result.status) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            'OK',
                            'success'
                        )
                    } else {
                        this.$root.showMessage(
                            this.$t('common.form.title.error'),
                            response.data.result.message,
                            'dangers'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    }
}
</script>